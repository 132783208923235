import React, { Component } from "react";
import $ from "jquery";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.inputCompName = React.createRef();
    this.inputEmail = React.createRef();
    this.inputCompAddress = React.createRef();
    this.inputCompDo = React.createRef();
    this.inputName = React.createRef();
    this.inputNumber = React.createRef();
    this.inputHighT = React.createRef();
    this.inputLowT = React.createRef();
    this.inputOver = React.createRef();
    this.inputType = React.createRef();
  }

  render() {
    function validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    }

    var isTrue = false;

    var sendform = (function () {
      return function (
        userName,
        userCompName,
        userNumber,
        userEmail,
        userCompAddress,
        userCompDo,
        userHighT,
        userLowT,
        userOver,
        userType
      ) {
        if (isTrue === true) {
          fetch("https://formsubmit.co/ajax/info@elnor.ba", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify({
              Naziv_Kompanije: userCompName,
              Email: userEmail,
              Adresa_Kompanije: userCompAddress,
              Djelatnost_Kompanije: userCompDo,
              Kontakt_Osoba: userName,
              Broj_Telefona: userNumber,
              Potrosnja_Visoka_Tarifa_kWh: userHighT,
              Potrosnja_Niska_Tarifa_kWh: userLowT,
              Opterecenje_kW: userOver,
              Tip_Poslovanja: userType,
            }),
          }).then((response) => response.json());
          //.then((data) => console.log(data))
          //.catch((error) => console.log(error));
        }
      };
    })();

    const handleSubmit = (event) => {
      event.preventDefault();
      let successMsg = this.success;
      let errorMsg = this.error;
      let userCompName = this.inputCompName;
      let userEmail = this.inputEmail;
      let userCompAddress = this.inputCompAddress;
      let userCompDo = this.inputCompDo;
      let userName = this.inputName;
      let userNumber = this.inputNumber;
      let userHighT = this.inputHighT;
      let userLowT = this.inputLowT;
      let userOver = this.inputOver;
      let userType = this.inputType;

      let userInfo = [
        userName,
        userCompName,
        userNumber,
        userEmail,
        userCompAddress,
        userCompDo,
        userHighT,
        userLowT,
        userOver,
        userType,
      ];

      var validation = validateEmail(userEmail.value);

      const valMail = (function () {
        if (!userEmail.value || validation === false) {
          return false;
        } else if (userEmail.value && validation === true) {
          return true;
        }
      })();

      userInfo.forEach((element) => {
        if (valMail === false) {
          $(userEmail).css("boxShadow", "0px 0px 10px red");
          $(successMsg).hide("fast");
          $(errorMsg).show("slow");
        }

        if (!element.value) {
          $(element).css("boxShadow", "0px 0px 10px red");
          $(successMsg).hide("fast");
          $(errorMsg).show("slow");
        }
        if (element.value) {
          $(element).css("boxShadow", "none");
          if (
            userName.value &&
            userCompName.value &&
            userNumber.value &&
            userEmail.value &&
            userCompAddress.value &&
            userCompDo.value &&
            userHighT.value &&
            userLowT.value &&
            userOver.value &&
            userType.value &&
            valMail === true
          ) {
            $(errorMsg).hide("fast");
            $(successMsg).show("slow");
            $(element).css("boxShadow", "none");

            isTrue = true;

            setTimeout(() => {
              this.form.reset();
            }, 10);

            setTimeout(() => {
              $(successMsg).hide("slow");
            }, 10000);
          }
        }
      });

      sendform(
        userName.value,
        userCompName.value,
        userNumber.value,
        userEmail.value,
        userCompAddress.value,
        userCompDo.value,
        userHighT.value,
        userLowT.value,
        userOver.value,
        userType.value
      );
    };

    const checkup = (element) => {
      if (element.value === "") {
        $(element).css("boxShadow", "0px 0px 10px red");
      } else {
        $(element).css("boxShadow", "none");
      }
    };

    const checkupMail = (element) => {
      const test = validateEmail(element.value);

      if (test === false) {
        $(element).css("boxShadow", "0px 0px 10px red");
      } else {
        $(element).css("boxShadow", "none");
      }
    };

    return (
      <section id="contact">
        <div className="mapouter"><div className="gmap_canvas"><iframe width="100%" height="450" id="gmap_canvas" src="https://maps.google.com/maps?q=Zanatska%2034,%20Prijedor,%20Bosna%20i%20Hercegovina&t=&z=17&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe><a href="https://putlocker-is.org"></a><br/><style>.mapouter{`position:relative;text-align:right;height:450px;width:100%;`}</style><style>.gmap_canvas {`overflow:hidden;background:none!important;height:450px;width:100%;`}</style></div></div>

        <div id="contact-us" className="parallax cntct">
          <div id="contact-anch" className="container">
            <div className="row">
              <div className="heading text-center col-sm-8 col-sm-offset-2">
                <h2>DOBIJTE NAJBOLJU PONUDU</h2>
                <p>
                  Molimo popunite ovaj formular i pošaljite da dobijete najbolju
                  ponudu. Povratne informacije ćemo vam dati u roku od 24 sata
                  nakon slanja službenog zahtjeva!
                </p>
              </div>
            </div>
            <div className="contact-form ">
              <div className="row">
                <div className="col-sm-6">
                  <form
                    id="contact-form my-form"
                    action="https://formsubmit.co/info@elnor.ba"
                    method="POST"
                    role="form"
                    noValidate
                    ref={(node) => (this.form = node)}
                  >
                    <div className="row ">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <input
                            id="form_name"
                            type="text"
                            name="comp"
                            className="form-control"
                            placeholder="Naziv kompanije"
                            required="required"
                            ref={(node) => (this.inputCompName = node)}
                            onBlur={(element) => {
                              checkup(element.target);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <input
                            id="from_email"
                            type="email"
                            name="email"
                            className="form-control"
                            placeholder="Email Adresa"
                            required="required"
                            ref={(node) => (this.inputEmail = node)}
                            onBlur={(element) => {
                              checkupMail(element.target);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <input
                        id="from_adres"
                        type="text"
                        name="address"
                        className="form-control"
                        placeholder="Adresa kompanije"
                        required="required"
                        ref={(node) => (this.inputCompAddress = node)}
                        onBlur={(element) => {
                          checkup(element.target);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        id="form_company"
                        type="text"
                        name="cdo"
                        className="form-control"
                        placeholder="Djelatnost kompanije"
                        required="required"
                        ref={(node) => (this.inputCompDo = node)}
                        onBlur={(element) => {
                          checkup(element.target);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        id="form_contact"
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Kontakt osoba"
                        required="required"
                        ref={(node) => (this.inputName = node)}
                        onBlur={(element) => {
                          checkup(element.target);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        id="form_phone"
                        type="number"
                        name="number"
                        className="form-control"
                        placeholder="Broj telefona"
                        required="required"
                        ref={(node) => (this.inputNumber = node)}
                        onBlur={(element) => {
                          checkup(element.target);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label type="text">
                        <font size="4" color="#FFFFFF">
                          Potrošnja energije
                        </font>
                      </label>
                    </div>
                    <div className="form-group">
                      <input
                        id="form_aeehigh"
                        type="text"
                        name="hight"
                        className="form-control"
                        placeholder="Potrošnja - visoka tarifa kWh"
                        required="required"
                        ref={(node) => (this.inputHighT = node)}
                        onBlur={(element) => {
                          checkup(element.target);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        id="form_price1"
                        type="text"
                        name="lowt"
                        className="form-control"
                        placeholder="Potrošnja - niska tarifa kWh"
                        required="required"
                        ref={(node) => (this.inputLowT = node)}
                        onBlur={(element) => {
                          checkup(element.target);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        id="form_aeelow"
                        type="text"
                        name="overload"
                        className="form-control"
                        placeholder="Opterecenje kW"
                        required="required"
                        ref={(node) => (this.inputOver = node)}
                        onBlur={(element) => {
                          checkup(element.target);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        id="form_price2"
                        type="text"
                        name="typec"
                        className="form-control"
                        placeholder="Tip poslovanja"
                        required="required"
                        ref={(node) => (this.inputType = node)}
                        onBlur={(element) => {
                          checkup(element.target);
                        }}
                      />
                    </div>
                    <div
                      className="text-center text-md-start"
                      id="success"
                      ref={(node) => (this.success = node)}
                    >
                      <p>Poruka uspješno poslana!</p>
                    </div>

                    <div
                      className="text-center text-md-start"
                      id="error"
                      ref={(node) => (this.error = node)}
                    >
                      <p>Molimo vas da popunite neophodna polja!</p>
                    </div>
                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn-submit button1"
                        onClick={handleSubmit}
                      >
                        Pošalji
                      </button>
                    </div>
                  </form>
                </div>
                <div className="col-sm-6">
                  <div className="contact-info">
                    <p>Kontakt:</p>
                    <ul className="address">
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-geo-alt"
                          viewBox="0 0 16 16"
                        >
                          <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                          <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                        </svg>{" "}
                        <span> Adresa: </span>
                        Ul. Zanatska 34, Prijedor, Bosna i Hercegovina
                      </li>
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-telephone"
                          viewBox="0 0 16 16"
                        >
                          <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                        </svg>
                        <span> Telefon: </span> +387 52 911 480{" "}
                      </li>
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-envelope"
                          viewBox="0 0 16 16"
                        >
                          <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                        </svg>{" "}
                        <span> Email: </span>
                        <a href="mailto:info@elnor.ba ">info@elnor.ba</a>
                      </li>
                      <li>
                        <svg
                          x="0px"
                          y="0px"
                          width="16"
                          height="16"
                          viewBox="0 100 500 300"
                          fill="white"
                        >
                          <path
                            d="M245,0C109.69,0,0,109.69,0,245s109.69,245,245,245s245-109.69,245-245S380.31,0,245,0z M31.401,260.313h52.542
    c1.169,25.423,5.011,48.683,10.978,69.572H48.232C38.883,308.299,33.148,284.858,31.401,260.313z M320.58,229.688
    c-1.152-24.613-4.07-47.927-8.02-69.572h50.192c6.681,20.544,11.267,43.71,12.65,69.572H320.58z M206.38,329.885
    c-4.322-23.863-6.443-47.156-6.836-69.572h90.913c-0.392,22.416-2.514,45.709-6.837,69.572H206.38z M276.948,360.51
    c-7.18,27.563-17.573,55.66-31.951,83.818c-14.376-28.158-24.767-56.255-31.946-83.818H276.948z M199.961,229.688
    c1.213-24.754,4.343-48.08,8.499-69.572h73.08c4.157,21.492,7.286,44.818,8.5,69.572H199.961z M215.342,129.492
    c9.57-37.359,21.394-66.835,29.656-84.983c8.263,18.148,20.088,47.624,29.66,84.983H215.342z M306.07,129.492
    c-9.77-40.487-22.315-73.01-31.627-94.03c11.573,8.235,50.022,38.673,76.25,94.03H306.07z M215.553,35.46
    c-9.312,21.02-21.855,53.544-31.624,94.032h-44.628C165.532,74.13,203.984,43.692,215.553,35.46z M177.44,160.117
    c-3.95,21.645-6.867,44.959-8.019,69.572h-54.828c1.383-25.861,5.968-49.028,12.65-69.572H177.44z M83.976,229.688H31.401
    c1.747-24.545,7.481-47.984,16.83-69.572h46.902C89.122,181.002,85.204,204.246,83.976,229.688z M114.577,260.313h54.424
    c0.348,22.454,2.237,45.716,6.241,69.572h-47.983C120.521,309.288,115.92,286.115,114.577,260.313z M181.584,360.51
    c7.512,31.183,18.67,63.054,34.744,95.053c-10.847-7.766-50.278-38.782-77.013-95.053H181.584z M273.635,455.632
    c16.094-32.022,27.262-63.916,34.781-95.122h42.575C324.336,417.068,284.736,447.827,273.635,455.632z M314.759,329.885
    c4.005-23.856,5.894-47.118,6.241-69.572h54.434c-1.317,25.849-5.844,49.016-12.483,69.572H314.759z M406.051,260.313h52.548
    c-1.748,24.545-7.482,47.985-16.831,69.572h-46.694C401.041,308.996,404.882,285.736,406.051,260.313z M406.019,229.688
    c-1.228-25.443-5.146-48.686-11.157-69.572h46.908c9.35,21.587,15.083,45.026,16.83,69.572H406.019z M425.309,129.492h-41.242
    c-13.689-32.974-31.535-59.058-48.329-78.436C372.475,68.316,403.518,95.596,425.309,129.492z M154.252,51.06
    c-16.792,19.378-34.636,45.461-48.324,78.432H64.691C86.48,95.598,117.52,68.321,154.252,51.06z M64.692,360.51h40.987
    c13.482,32.637,31.076,58.634,47.752,78.034C117.059,421.262,86.318,394.148,64.692,360.51z M336.576,438.54
    c16.672-19.398,34.263-45.395,47.742-78.03h40.99C403.684,394.146,372.945,421.258,336.576,438.54z"
                          />
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                        </svg>{" "}
                        <span>Web stranice: </span>{" "}
                        <a href="http://www.elnor.mk" target="_blank">
                          www.elnor.mk
                        </a>
                        , <a href="/">www.elnor.ba</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Contact;
