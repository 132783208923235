import React from "react";
import "./App.css";
import Navbar from "./components/1. Navbar/navbar";
import { Routes, Route } from "react-router";
import Home from "./components/2. Home/home";
import Footer from "./components/3. Footer/footer";


function App() {
  return (
    <React.Fragment>
      <Navbar />
      <Routes>
        <Route path="/" exact element={<Home />} />
      </Routes>
      <Footer />
    </React.Fragment>
  );
}

export default App;
