import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import logo from "../_bmp/elnor-logo.gif";
import MenuElement from "./common/menuelement";
import MenuIcon from "./common/menu";
import { HashLink } from "react-router-hash-link";

class Navbar extends Component {
  state = {
    visible: "",
    active: "",
  };

  render() {
    const { visible, active } = this.state;
    const engLink = "https://elnor.mk/";

    const reactiveMenu = (navData) => {
      let cls = `nav-link menu-link`;
      return navData.isActive ? (cls += "-active") : cls;
    };

    const openMenu = () => {
      if (visible === "") {
        this.setState({ visible: "showMenu2" });
      } else {
        this.setState({ visible: "" });
      }

      if (active === "") {
        this.setState({ active: "mb-2" });
      } else {
        this.setState({ active: "" });
      }
    };

    return (
      <React.Fragment>
        <header id="mainMenuLine" className="mainMenuLine stick">
          <div id="mmwidth" className="px-3 py-2 text-props">
            <div className="container">
              <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
                <img src={logo} />

                <div className="d-flex align-items-center my-2 my-lg-0 me-lg-auto text-white text-decoration-none"></div>

                <ul className="nav col-12 col-lg-auto my-2 justify-content-center my-md-0 text-small">
                  <li id={visible}>
                    <HashLink
                      to="#"
                      className={`reactiveMenu`}
                      onClick={openMenu}
                    >
                      <MenuElement name="Početna" />
                    </HashLink>
                  </li>

                  <li id={visible}>
                    <HashLink
                      to="/#services-anchor"
                      className={`reactiveMenu`}
                      onClick={openMenu}
                    >
                      <MenuElement name="Usluge" />
                    </HashLink>
                  </li>

                  <li id={visible}>
                    <HashLink
                      to="/#about-anchor"
                      className={`reactiveMenu`}
                      onClick={openMenu}
                    >
                      <MenuElement name="O nama" s />
                    </HashLink>
                  </li>

                  <li id={visible}>
                    <NavLink to="/" className={`reactiveMenu`} onClick={openMenu}>
                      <MenuElement name="Novosti" />
                    </NavLink>
                  </li>

                  <li id={visible}>
                    <HashLink
                      to="/#contact-anch"
                      className={`reactiveMenu`}
                      onClick={openMenu}
                    >
                      <MenuElement name="Kontakt" />
                    </HashLink>
                  </li>

                  <li id={visible}>
                    <NavLink to="/" className={reactiveMenu} onClick={openMenu}>
                      <MenuElement name="|" />
                    </NavLink>
                  </li>

                  <li id={visible}>
                    <NavLink to="/" className={reactiveMenu} onClick={openMenu}>
                      <MenuElement name="BA" />
                    </NavLink>
                  </li>
                  <li id={visible}>
                    <a
                      href={engLink}
                      target="_blank"
                      className={`reactiveMenu nav-link menu-link-active`}
                      onClick={openMenu}
                    >
                      <MenuElement name="ENG" />
                    </a>
                  </li>

                  <i
                    className={`text-white phoneMenu`}
                    id="phoneMenuClick"
                    onMouseDownCapture={openMenu}
                  >
                    <MenuElement name="" svg={MenuIcon(active)} />
                  </i>
                </ul>
              </div>
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

export default Navbar;
