import info1 from "../components/_bmp/1.jpg";
import info2 from "../components/_bmp/2.jpg";
import info3 from "../components/_bmp/3.jpg";
import info4 from "../components/_bmp/4.jpg";
import info5 from "../components/_bmp/5.jpg";

const Info = () => {
  return (
    <section id="about">
      <div className="container">
        <div className="row">
          <div className="about-info">
            <h3>
              <a href="#">Licenca</a>
            </h3>
            <img src={info1} alt="" width="150" height="143" align="right" />
            1. Posjednik dozvole za obavljanje energetske djelatnosti
            snabdijevanja energijom
            <br />
            2. Poziv na broj izdate dozvole EE-199.11.1/14
            <br />
            3. EIC IDENTIFIKACIJSKI KOD: 33 ELNORSUPLY-9
            <br />
            4. Broj poslovnog subjekta -6958427
            <br />
            5. Energetskim poslovima snabdijevanja električnom energijom (sa
            ovom licencom) možemo se baviti na cijeloj teritoriji Republike
            Makedonije iu inostranstvu.
          </div>
          <div className="about-info">
            <h3>
              <a href="#">Poslovne vrijednosti</a>
            </h3>
            <img src={info2} alt="" width="150" height="141" align="right" />
            Elnor se u svom radu pridržava sljedećih principa:
            <br />
            1. Zakonitost
            <br />
            2. Stručnost
            <br />
            3. Profesionalizam
            <br />
            4. Transparentnost
            <br />
            5. Odgovornost za rad
            <br />
            ELNOR radi za građane Republike Makedonije u cilju popularizacije
            primjene novih evropskih i svjetskih EE tehnologija i podizanja
            svijesti za efikasno korištenje energije.
            <br /> Stoga je važno shvatiti da EE ne znači uštedu, već racionalno
            korištenje energije za postizanje istog ili višeg nivoa udobnosti u
            industriji i svakodnevnom životu, na račun manje utrošene energije.
          </div>
          <div className="about-info">
            <h3>
              <a href="#">Poslovni energetski vodič</a>
            </h3>
            <img src={info3} alt="" width="150" height="127" align="right" />
            Ako ste finansijski odgovorni za posao, bolje ćete od ikoga znati
            koliko je važno smanjiti režijske troškove kompanije. Iako postoji
            niz različitih načina za smanjenje tekućih troškova vašeg
            poslovanja, osiguravajući da trošite što manje na svoju poslovnu
            energiju odličan je način za uštedu novca iz dana u dan. Naravno,
            postoji nekoliko načina na koje možete uštedjeti novac na poslovnoj
            struji: korištenje štedljivih sijalica, osiguranje isključivanja
            monitora i svjetla preko noći ili čak traženje osoblja da prokuha
            vodu koja im je potrebna kada se tuširaju. Sve su to sjajne ideje,
            ali jedan od najboljih načina da smanjite svoje račune je smanjenje
            poslovne cijene energije promjenom dobavljača energije. Ovo može
            biti prilično težak poduhvat u zavisnosti od veličine vaše
            kompanije, ali na duge staze može biti vrijedan trud - promjena
            dobavljača može napraviti ogromnu razliku u mjesečnim prihodima i
            rashodima vašeg poslovanja.
            <br />
            Naravno, postoji nekoliko načina na koje možete uštedjeti novac na
            poslovnoj struji: korištenje štedljivih sijalica, osiguranje
            isključivanja monitora i svjetla preko noći ili čak traženje od
            osoblja da prokuha vodu koja im je potrebna kada se tuširaju. Sve su
            to sjajne ideje, ali jedan od najboljih načina da smanjite svoje
            račune je smanjenje poslovnih cijena energije promjenom dobavljača
            energije. Ovo može biti prilično težak poduhvat u zavisnosti od
            veličine vaše kompanije, ali na duge staze može biti vrijedan truda
            - promjena dobavljača može napraviti ogromnu razliku u mjesečnim
            prihodima i rashodima vašeg poslovanja.
            <div className="about-info">
              <h3>
                <a href="#">Energetska efikasnost</a>
              </h3>
              <img src={info4} alt="" width="150" height="137" align="right" /><p>
              1. U industriji, građevinarstvu i transportu
              <br />
              2. Racionalno korištenje energije i resursa
              <br />
              3. Instalacija sistema sa visokim količnikom EE
              <br />
              4. Upotreba savremenog sistema za automatsko upravljanje
              <br />
              5. Profesionalne kontrole energije za identifikaciju najplativijih
              mjera za EE
              <br />
              6. Pravilno upravljanje sistemom i instrumentima
              <br />
              7. Briga za životnu sredinu
              </p>
            </div>
            <div className="about-info">
              <h3>
                <a href="#">Ako se prebacite</a>
              </h3>
              <img src={info5} alt="" width="150" height="144" align="right" />
              1. Ne postoji opasnost od prekida napajanja.
              <br />
              2. Neće vam trebati novi mjerač.<br />
              3. Električna energija koju dobijete u svojoj kompaniji će ostati
               isto. <br />
               4. Razlika koju ćete vidjeti je da će vam račun biti iz novog dobavljač i sa znatno nižom količinom!
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Info;
