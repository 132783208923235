import { faDiceOne } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import slide1 from "../components/_bmp/delivering-energy.jpg";
import slide2 from "../components/_bmp/our-mission.jpg";
import slide3 from "../components/_bmp/our-solution.jpg";
import { useSpring, animated } from "react-spring";

const QH1 = (
  <h1 className="animated fadeInLeftBig">
    ISPORUČUJEMO ENERGIJU <span>VAMA</span>
  </h1>
);

const QH2 = (
  <h1 className="animated fadeInLeftBig">
    NAŠA <span>MISIJA</span>
  </h1>
);
const QH3 = (
  <h1 className="animated fadeInLeftBig">
    NAŠA <span>RIJEŠENJA</span>
  </h1>
);

const QP1 = (
  <p className="animated fadeInRightBig">
    Prenosimo nordijsko iskustvo i principe na makedonsko tržište električne
    energije
  </p>
);

const QP2 = (
  <p className="animated fadeInRightBig">
    Pružanje dugoročnih prednosti za potrošače i doprinos Makedonskoj ekonomiji i
    šire u regionu
  </p>
);
const QP3 = (
  <p className="animated fadeInRightBig">
    PRUŽAMO RJEŠENJA KOJA SE ODNOSE NA SNABDIJEVANJE ELEKTRIČNOM ENERGijom PO
    NAJBOLJIM EVROPSKIM STANDARDIMA I NAJNIŽIM CIJENAMA
  </p>
);

const Slideshow = () => {
  const slides = [slide1, slide2, slide3];
  const QH = [QH1, QH2, QH3];
  const QP = [QP1, QP2, QP3];
  const delay = 5000;
  const [currentIndex, setCurrentIndex] = useState(0);

  const timeoutRef = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setCurrentIndex((prevIndex) =>
          prevIndex === slides.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [currentIndex]);

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const leftArr = useSpring({
    to: { opacity: 1, marginLeft: 0 },
    from: { opacity: 0, marginLeft: -1000 },
    //delay: 100,
    config: { duration: 600 },
    
  });

  const rightArr = useSpring({
    to: { opacity: 1, marginRight: 0 },
    from: { opacity: 0, marginRight: -1000 },
    //delay: 100,
    config: { duration: 600 },
  });

  const fadeIn = () => {
  
  };

  return (
    <div className="slide-container">
      <animated.div
        style={leftArr}
        className="arrow-left"
        onClick={() => {
          goToPrevious();
          fadeIn();
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="1 1 22 22">
          <g data-name="Circle kiri">
            <path d="M13 16a1 1 0 0 1-.707-.293l-3-3a1 1 0 0 1 0-1.414l3-3a1 1 0 0 1 1.414 1.414L11.414 12l2.293 2.293A1 1 0 0 1 13 16z" />
          </g>
        </svg>
      </animated.div>

      <animated.div
        style={rightArr}
        className="arrow-right"
        onClick={() => {
          goToNext();
          fadeIn();
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="1 1 22 22">
          <g data-name="Circle kanan">
            <path d="M11 16a1 1 0 0 1-.707-1.707L12.586 12l-2.293-2.293a1 1 0 0 1 1.414-1.414l3 3a1 1 0 0 1 0 1.414l-3 3A1 1 0 0 1 11 16z" />
          </g>
        </svg>
      </animated.div>
      <div
        /*onMouseEnter={() => {
          fadeIn();
        }}*/
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${slides[currentIndex]})`,
        }}
        className="slide"
      >
        <div className="caption">
          <animated.div style={leftArr}>{QH[currentIndex]}</animated.div>
          <animated.div style={rightArr}>{QP[currentIndex]}</animated.div>
        </div>
      </div>
    </div>
  );
};

export default Slideshow;
