const About = () => {
  return (
    <section id="about-us" className="parallax about-section">
      <div className="container">
        <div className="row">
          <div className="col-sm-6">
            <div className="about-info">
              <h2>O nama</h2>
              <p>
                Elnor je novi snabdjevač električnom energijom u Makedoniji sa
                svježim stavom prema upravljanju energijom. Prenosimo Nordijsko
                iskustvo i principe na Makedonsko tržište električne energije. U
                Elnoru vjerujemo da je najbolji način da stvorimo kulturu na
                koju možemo biti ponosni živjeti vrijednosti koje posjedujemo.
                Naše vrijednosti govore šta smo, kako radimo i gdje želimo biti.
                <br />
              </p>
              <h3>Radimo na tome da osiguramo</h3>
              <p>
                Potrošači imaju širok izbor dobavljača i proizvoda unutar visoko
                inovativnog i konkurentnog maloprodajnog sektora. Tržište
                električne energije efikasno zadovoljava buduće potrebe zemlje
                za električnom energijom - i upravlja potrošnjom električne
                energije. Cijene električne energije su u skladu sa
                funkcionalnom konkurencijom na svim lokacijama i u svakom
                trenutku tržišni aranžmani podržavaju inovacije i investicije.
              </p>
              <br />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="about-info">
              <h3>Empatija</h3>
              <p>
                Naši klijenti su na prvom mjestu. Pružamo odličnu uslugu
                klijentima fokusirajući se na potrebe naših klijenata
                jednostavno i efikasno.
              </p>

              <h3>Jasnoća</h3>
              <p>
                Držimo ga jednostavnim. Pažljivo slušamo i jasno komuniciramo.
                Cilj nam je zajedničko razumijevanje.
              </p>

              <h3>Integritet</h3>
              <p>
                Otvoreni smo i pošteni. Radimo na etički i neposredan način i
                poštujemo obaveze i obećanja koja dajemo.
              </p>

              <h3>Timski rad</h3>
              <p>Radimo zajedno kao jedan tim.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
