import offer from "../components/_bmp/we-offer.png";
import integrity from "../components/_bmp/integrity.png";
import clarity from "../components/_bmp/clarity.png";

const Services = () => {
  return (
    <section id="services-anchor" >
      <div className="container services">
        <div className="heading">
          <div className="row">
            <div className="text-center col-sm-8 col-sm-offset-2">
              <h2>Naše usluge</h2>
              <p>
                Naše vrijednosti govore šta smo, kako radimo i gdje želimo biti.
              </p>
            </div>
          </div>
        </div>
        <div className="text-center our-services">
          <div className="row">
            <div className="col-sm-4">
              <div className="service-icon">
                <i>
                  <img src={offer} alt="logo" />
                </i>
              </div>
              <div className="service-info">
                <h3>Mi nudimo</h3>
                <p>
                  Konkurentne cijene električne energije
                  <br />
                  Prijateljska i pristupačna usluga 24/7 kontakt centar za
                  korisnike <br />
                  Lako razumljivi računi i dokumenti
                </p>
              </div>
            </div>

            <div className="col-sm-4">
              <div className="service-icon">
                <i>
                  <img src={integrity} alt="logo" />
                </i>
              </div>
              <div className="service-info">
                <h3>Integritet</h3>
                <p>
                Otvoreni smo i pošteni<br/>Radimo na etičan i direktan način i poštujemo obaveze i obećanja koja dajemo
                </p>
              </div>
            </div>

            <div className="col-sm-4">
              <div className="service-icon">
                <i>
                  <img src={clarity} alt="logo" />
                </i>
              </div>
              <div className="service-info">
                <h3>Jasnoća</h3>
                <p>
                Držimo stvari jednostavnima<br/>Pažljivo slušamo i jasno komuniciramo<br/>Cilj nam je zajedničko razumijevanje
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span id="about-anchor"></span>
    </section>
  );
};

export default Services;
