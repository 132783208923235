import React from "react";

const MenuIcon = (active) => {
  return (
    <svg
                    className={`bi d-block mx-auto mb-1 bi bi-cart2 ${active}`}
                    width="45"
                    height="45"
                    fillRule="currentColor"
                    id="menuColorChange"
                    viewBox="0 3 16 10"
                  >
                    <path
                      fillRule="evenodd"
                      d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                    />
                  </svg>
  );
};

export default MenuIcon;
