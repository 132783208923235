import { Component } from "react";
import About from "../../common/about";
import Contact from "../../common/contact";
import Info from "../../common/info";
import Services from "../../common/services";
import Slideshow from "../../common/slideshow";

class Home extends Component {
  render() {
    return (
      <div>
        <Slideshow />
        <Services />
        <About />
        <Info />
        <Contact />
      </div>
    );
  }
}

export default Home;
